export enum Identifiers {
    HomeBrand = 'home-brand',
    RecentlyView = 'recently-view',
    NewCollection = 'new-collection',
    TrendingBanner = 'trending-banner',
    ProductMatching = 'jordan-matching-widget',
    TopCategories = 'top-categories',
    FeatureProduct = 'featured-products',
    Recommended = 'recommended',
    Footer = 'footer-area',
    Newsletter = 'newsletter',
    NewsletterBackground = 'newsletter-background',
    NewAbout = 'about-area',
    CopyRight = 'copy-right-area',
    ProductDetailBanner = 'product-detail-banner',
    FAQ = 'faq',

    CheckoutTerms = 'checkout-terms',
    FAQProduct = 'faq-product',
    VeryfiedPurchase = 'veryfied-purchase',
    ThankYou = 'thank-you-notice',
    ProductRefund = 'product-refund',
    ReviewCodeDiscount = 'review-code-discount',
    MemberBenefit = 'member-benefit',
    Affiliate = 'affiliate',
    CutOff = 'cut-off',
    CutOffAfter = 'cut-off-after',
    SignUpBanner = 'sign-up-banner',
    SignInBanner = 'sign-in-banner'
}

export enum RelatedProductCode {
    ProductsRelatedToThisItem = 'related1',
    CustomersWhoViewedThisItemAlsoViewed = 'related2',
    CustomersWhoBoughtThisItemAlsoBought = 'related3',
    ExclusiveItemsFromOurBrands = 'related4',
    InspiredByYourBrowsingHistory = 'related5'
}
export enum RelatedPosition {
    CategoruSidebarBottom = 'category_sidebar_bottom',
    CartContentBottom = 'cart_content_bottom',
    ProductContentBottom = 'product_content_bottom',
    ProductContentTop = 'product_content_top',
    HomePage = 'homepage'
}

export const enum SectionsKey {
    Hero = 'Hero',
    Brand = 'Brand',
    ProductMatching = 'ProductMatching',
    RecentlyView = 'RecentlyView',
    TopCategory = 'TopCategory',
    BestSeller = 'BestSeller',
    MostPopular = 'MostPopular',
    FeatureProduct = 'FeaturedProducts',
    NewCollections = 'NewCollection',
    Trending = 'Trending',
    Recommended = 'Recommended',
    Testimonial = 'Testimonial',

    // product detail
    Breadcrumb = 'Breadcrumb',
    ProductDetailTwo = 'ProductDetailTwo',
    ProductDetail = 'ProductDetail',
    ProductDetailTabs = 'ProductDetailTabs',
    ProductRelated = 'ProductRelated',
    ProductRelatedCart = 'ProductRelatedCart',
    Related = 'Related',
    ProductDetailBanner = 'ProductDetailBanner',
    ProductDetailMoreInfo = 'ProductDetailMoreInfo',
    ProductTags = 'ProductTags',

    // checkout
    Checkout = 'Checkout',

    //product list
    ProductList = 'ProductList',

    //blog
    BlogList = 'BlogList',
    BlogDetail = 'BlogDetail',
    RelatedBlogs = 'RelatedBlogs',

    //profile
    DeliveryAddresses = 'DeliveryAddresses',
    ProfileInformation = 'ProfileInformation',

    //order
    OrderDetail = 'OrderDetail',

    //category
    Category = 'Category',
    Collections = 'Collections',

    //collection
    CollectionsAbout = 'CollectionsAbout',
    CollectionsRecommend = 'CollectionsRecommend',
    CollectionsContent = 'CollectionsContent',

    //Review
    Reviews = 'Reviews',
    TabReviews = 'TabReviews',
    ReviewShop = 'ReviewShop',

    //launch
    Launch = 'Launch',
    ViewAllLaunchProduct = 'ViewAllLaunchProduct',

    ProductDetailSimilar = 'ProductDetailSimilar',
    SimilarProduct = 'SimilarProduct',
    // History
    History = 'History',
    // cart
    Cart = 'Cart',
    // 404
    NotFound = 'NotFound',

    //NewsLetter
    NewsLetter = 'NewsLetter',
    // CMS-Page
    AboutUs = 'AboutUs',
    CMSPage = 'CMSPage',
    Affiliate = 'Affiliate'
}

export const enum CmsBlockProductList {
    BestSeller = 'best-sellers',
    Poppular = 'popular',
    Trending = 'trending',
    Category = 'category',
    Collection = 'collection'
}
export const enum MegaMenuType {
    Wrapper = 'wrapper',
    CmsBlock = 'cms_block',
    Category = 'category',
    CustomUrl = 'custom_url',
    CmsPage = 'cms_page',
    Product = 'product'
}

export const enum PATH {
    Blogs = '/blog',
    BlogDetail = '/blog/[slug]',
    Categories = '/categories',
    CategoryDetail = '/categories/[slug]',
    Collections = '/collections',
    CollectionDetail = '/collections/[slug]',
    OrderDetail = '/orders/[slug]',
    ProductDetail = '/products/[slug]',
    ProductSimilarDetail = '/products/[slug]/similar',
    ProductCustom = '/products/custom/[slug]',
    ProfileDeliveryAddress = '/profile/delivery-address',
    ProfileInformation = '/profile/information',
    ProfileOrderHistory = '/profile/order-history',
    SignIn = '/sign-in',
    SignUp = '/sign-up',
    CmsPage = '/[cmsPage]',
    About = '/about',
    CancelPaypal = '/cancel-paypal',
    Carts = '/carts',
    Checkout = '/checkout',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password',
    Reviews = '/reviews',
    Search = '/search',
    ThankYou = '/thank-you',
    Wishlist = '/Wishlist',
    TrackOrder = '/tracking-order',
    ConnectFacebook = '/connect-facebook',
    ConnectGoogle = '/connect-google',
    SneakerRelease = '/sneaker-release',
    SneakerReleaseBrand = '/sneaker-release/[brand]',
    SneakerReleaseDetail = '/sneaker-release/[brand]/[slug]'
}

export enum ShortCodeFilterKey {
    category_uid = 'ca',
    category_master = 'ty',
    Color = 'co',
    size = 'si',
    frame = 'fr',
    style = 'st'
}
export enum LongCodeFilterKey {
    ca = 'category_uid',
    ty = 'category_master',
    co = 'color',
    si = 'size',
    fr = 'frame',
    st = 'style'
}
export enum ProductListKey {
    BestSeller = 'bs',
    Poppular = 'pp',
    Trending = 'tr',
    Category = 'ca',
    Collection = 'co',
    RecentlyView = 'rv',
    Recommended = 'rc',
    Similar = 'sl'
}
export const ProductListText = {
    [ProductListKey.BestSeller]: 'Best Seller',
    [ProductListKey.Poppular]: 'Poppular',
    [ProductListKey.Trending]: 'Trending',
    [ProductListKey.Category]: 'Category',
    [ProductListKey.Collection]: 'Collection',
    [ProductListKey.RecentlyView]: 'Recently View',
    [ProductListKey.Recommended]: 'Recommended',
    [ProductListKey.Similar]: 'Similar'
};
